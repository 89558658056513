import { ServerResponse } from 'http';
import Router from 'next/router';

export default function redirectTo(
  destination: string,
  { res, status }: { res: ServerResponse | undefined; status: number }
): void {
  if (res) {
    res.writeHead(status || 302, { Location: destination });
    //res.redirect(destination)
    res.end();
  } else {
    if (destination[0] === '/' && destination[1] !== '/') {
      Router.push(destination);
    } else {
      window.location = (destination as unknown) as Location;
    }
  }
}
